import { useState, useContext, useEffect } from "react";
import Header from "../components/Header";
import Filter from "../components/Filter";
import DataList from "../components/DataList";
import AudioWaveForm from "../components/AudioWaveForm";
import Transcript from "../components/Transcript";
import AudioInfoModal from "../components/AudioInfoModal";
import { IndicVoiceContext } from "../state/IndicVoiceContext";

const Explore = () => {
  const { data, setTimestamp } = useContext(IndicVoiceContext);
  const { timestamp } = data.currentAudioDetails;

  useEffect(() => {}, []);

  return (
    <div className=" bg-slate-100 min-h-screen">
      <div className="flex flex-col bg-slate-100 md:container md:mx-auto">
        <AudioInfoModal />
        <Header />
        <div className="mb-1"></div>
        <Filter />
        <DataList setTimestamp={setTimestamp} />
        <div className="mb-1"></div>
        <AudioWaveForm setTimestamp={setTimestamp} />
        <Transcript timestamp={timestamp} />
      </div>
    </div>
  );
};

export default Explore;
