import './App.css';
import { useContext, useEffect } from 'react';
import Home from './screens/Home';
import Explore from './screens/Explore';
import Download from './screens/Download';
import { IndicVoiceContext } from './state/IndicVoiceContext';


function App() {
  const { data } = useContext(IndicVoiceContext)
  const { currentPage } = data;

  useEffect(() => {
  }, [])

  return (
    <div className='bg-slate-100 h-full'>
      {currentPage === "home" ? <Home /> : currentPage === "explore" ? <Explore /> : <Download />}
    </div>
  );
}

export default App;
