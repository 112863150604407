import React from "react";

const TextInput = ({ type, placeholder, id, disabled, value, setValue }) => {
  return (
    <input
      type={type}
      id={id}
      disabled={disabled}
      autoComplete="off"
      className="bg-gray-50 border border-gray-300 text-gray-900 text-xs focus:ring-orange-300 rounded focus:border-orange-300 block w-full p-2.5"
      placeholder={placeholder}
      required
      min={type === "number" ? 0 : null}
      value={value}
      onChange={(event) => setValue(id, event.target.value)}
    ></input>
  );
};

export default TextInput;
