import React from "react";

const SubHeading = ({ heading }) => {
  return (
    <div className="flex flex-col mb-4">
      <h5 className="text-xl font-semibold">{heading}</h5>
      <hr className="bg-black-900 h-0.5" />
    </div>
  );
};

export default SubHeading;
