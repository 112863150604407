import { useContext, useEffect } from "react";
import Header from "../components/Header";
import Auth from "../components/Auth";
import DownloadList from "../components/DownloadList";
import { IndicVoiceContext } from "../state/IndicVoiceContext";

const Download = () => {
  const { data, getSavedToken } = useContext(IndicVoiceContext);
  const { jwt } = data.authInfo;

  useEffect(() => {
    getSavedToken();
  }, []);

  return (
    <div className="bg-slate-100 pb-16 h-screen">
      <div className="flex flex-1 flex-col gap-1 bg-slate-100 md:container md:mx-auto">
        <Header />
        {jwt !== "" ? <DownloadList /> : <Auth />}
      </div>
    </div>
  );
};

export default Download;
