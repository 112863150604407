import { useContext } from "react";
import TranscriptionBox from "./TranscriptionBox";
import { IndicVoiceContext } from "../state/IndicVoiceContext";

const Transcript = ({ timestamp }) => {
  const { data } = useContext(IndicVoiceContext);
  const { currentAudioInfo } = data.currentAudioDetails;

  return (
    <div className="rounded bg-white p-4">
      {Object.keys(currentAudioInfo).length !== 0 ? (
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
          <TranscriptionBox
            heading={"verbatim"}
            audioDetails={currentAudioInfo}
            timestamp={timestamp}
          />
          <TranscriptionBox
            heading={"normalized"}
            audioDetails={currentAudioInfo}
            timestamp={timestamp}
          />
        </div>
      ) : null}
    </div>
  );
};

export default Transcript;
