import React, { useEffect, useState } from "react";
import Select from "react-select";

const SearchableSelection = ({
  values,
  isMulti = false,
  id,
  selected,
  setSelected,
  primaryLanguage = "",
  placeholder,
  isDisabled = false,
}) => {
  const createOptions = (values) => {
    const unSelectedValues = Object.fromEntries(
      Object.entries(values).filter(([key, value]) => {
        return key !== selected;
      })
    );
    const keys = Object.keys(unSelectedValues).sort();
    const options = keys.map((key) => {
      const tmp = {
        value: key,
        label: values[key],
        isDisabled: key === primaryLanguage,
      };
      return tmp;
    });
    setOptions(options);
  };

  const createOptionsObject = (arr) => {
    if (typeof arr === "string") {
      return arr ? [{ value: arr, label: values[arr] }] : [];
    } else if (Array.isArray(arr)) {
      return Object.keys(arr).length
        ? arr.map((value) => {
            return { value: value, label: values[value] };
          })
        : [];
    } else return [];
  };

  const [options, setOptions] = useState([]);

  useEffect(() => {
    createOptions(values);
  }, [values, primaryLanguage, selected]);

  return (
    <Select
      inputId={id}
      options={options}
      isMulti={isMulti}
      isDisabled={isDisabled}
      styles={customSytles}
      value={createOptionsObject(selected)}
      closeMenuOnSelect={!isMulti}
      placeholder={<div>{placeholder}</div>}
      onChange={(obj) => {
        isMulti ? setSelected(id, obj) : setSelected(id, obj.value);
      }}
    />
  );
};

const customSytles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: "12px",
    lineHeight: "14px",
  }),
  control: (base, state) => ({
    ...base,
    boxShadow: state.isFocused ? 0 : 0,
    borderColor: state.isFocused ? "orange" : base.borderColor,
    "&:hover": {
      borderColor: state.isFocused ? "orange" : base.borderColor,
    },
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontSize: "12px",
    lineHeight: "14px",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontSize: "12px",
    lineHeight: "14px",
  }),
};

export default SearchableSelection;
