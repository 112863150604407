import metadata from "../assets/metadata.json";

const DownlaodListItem = ({ version, infos, token }) => {
  let splits = Object.groupBy(infos, ({ split }) => split);
  const checkSplitExist = (language, split) => {
    let exist = split in splits;
    if (exist) {
      const result =
        splits[split].filter((info) => {
          return info["language"] === language;
        }).length > 0;
      return result;
    }
    return exist;
  };

  let languages = Object.keys(metadata["languages"]).sort();
  const createUrl = (split, version, language) => {
    return `https://indicvoices.ai4bharat.org/backend/download_dataset/${version}_${language}_${split}.tgz?token=${token}`;
  };

  return (
    <div className="p-2 min-w-fit">
      <span className="text-sm font-bold capitalize ">
        Version: <span className="uppercase">{version}</span>
      </span>
      <div className="flex flex-row rounded">
        {languages.map((language, index, row) => {
          return (
            <div
              key={language}
              className="flex flex-col gap-2 text-center items-center"
            >
              <p
                className={`p-2 px-4 font-medium text-sm bg-slate-200 ${
                  index === 0 ? "rounded-tl" : ""
                } ${row.length - 1 === index ? "rounded-tr" : ""}`}
              >
                {language}
              </p>
              {checkSplitExist(language, "train") ? (
                <a
                  href={createUrl("train", version, language)}
                  className="text-xs text-blue-400 underline "
                >
                  Train
                </a>
              ) : (
                <p className="text-xs">-</p>
              )}
              {checkSplitExist(language, "valid") ? (
                <a
                  href={createUrl("valid", version, language)}
                  className="text-xs text-blue-400 underline "
                >
                  Valid
                </a>
              ) : (
                <p className="text-xs">-</p>
              )}

              {/* {checkSplitExist(language, "test") ? (
                <a
                  href={createUrl("test", version, language)}
                  className="text-xs text-blue-400 underline "
                >
                  Test
                </a>
              ) : ( */}
              <p className="text-xs">Test(soon)</p>
              {/* )} */}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DownlaodListItem;
