import { useContext, useEffect } from "react";
import Button from "./Button";
import DataListItem from "./DataListItem";
import { IndicVoiceContext } from "../state/IndicVoiceContext";

const DataList = ({ setTimestamp }) => {
  const { data, getData, setCurrentAudioFile } = useContext(IndicVoiceContext);
  const {
    audioDetails,
    audioFileList,
    loading,
    currentAudioDetails,
    filteredAudioDetails,
  } = data;

  useEffect(() => {
    if (Object.keys(audioDetails).length === 0) {
      getData();
    }
  }, []);

  const setCurrentAudio = (audioUrl, audioId, audioDetail) => {
    setCurrentAudioFile(audioUrl, audioId, audioDetail);
    setTimestamp(0.0);
  };

  return (
    <div className="rounded bg-white p-4 ">
      <div className="max-h-96 mb-2 rounded overflow-x-scroll">
        <table className="w-full rounded text-left shadow-md border-spacing-x-2 border-spacing-y-2">
          <thead className="sticky top-0 bg-slate-200">
            <tr className="">
              <th className="p-2 font-medium text-sm">Audio</th>
              <th className="font-medium text-sm">Filename</th>
              <th className="font-medium text-sm">Language</th>
              <th className="font-medium text-sm">Duration</th>
              <th className="font-medium text-sm">Gender</th>
              <th className="font-medium text-sm">Age Group</th>
              <th className="font-medium text-sm">State</th>
              <th className="font-medium text-sm">District</th>
              <th className="font-medium text-sm">Scenario</th>
              <th className="font-medium text-sm">Info</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(filteredAudioDetails).map((audioId, index) => {
              const audioDetail = filteredAudioDetails[audioId];
              const audioUrl =
                audioId in audioFileList ? audioFileList[audioId] : "";
              const currentPlayingAudioId =
                currentAudioDetails.currentAudioId || "";
              return (
                <DataListItem
                  key={index}
                  index={index}
                  info={audioDetail}
                  setTimestamp={setTimestamp}
                  audioExist={audioId in audioFileList}
                  currentPlayingAudio={currentPlayingAudioId === audioId}
                  setAudioData={() => {
                    setCurrentAudio(audioUrl, audioId, audioDetail);
                  }}
                />
              );
            })}
          </tbody>
        </table>
      </div>
      <Button onClick={getData} label={"Load more"} loading={loading} />
    </div>
  );
};

export default DataList;
