class Validator {
    _nameValidator = (name) => {
        const namePattern = new RegExp("^[A-Za-z]?([A-Za-z] ?)+$");
        return namePattern.test(name);
    }

    _organizationValidator = (name) => {
        const namePattern = new RegExp("^[A-Za-z .0-9]+$");
        return namePattern.test(name);
    }

    _phoneNumberValidator = (phoneNumber) => {
        if (!phoneNumber) return true;
        const phoneNumberPattern = new RegExp("^[0-9]{6,}$");
        return phoneNumberPattern.test(phoneNumber);
    };

    _emailValidator = (email) => {
        const emailPattern = new RegExp(
            "[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?"
        );
        return emailPattern.test(email);
    };

    _otpValidator = (otp) => {
        const otpPattern = new RegExp("[0-9]{6}$")
        return otpPattern.test(otp);
    }

    _basicCleaning = (value) => {
        if (!value) {
            return "";
        }
        value = value.toString().trim();
        return value;
    };

    validate = (type, value) => {
        let result = true;
        let msg = "";
        value = this._basicCleaning(value)
        switch (type) {
            case "name":
                result = this._nameValidator(value);
                if (!result) {
                    result = false;
                    msg = "Sorry, it seems like the name you entered is not valid. Please remove any special characters or numbers from the name"
                }
                break
            case "phone":
                result = this._phoneNumberValidator(value)
                if (!result) {
                    result = false
                    msg = "Sorry, it seems like the phone number you have entered is not valid. Make sure that the phone number has only numbers."
                }
                break
            case "otp":
                result = this._otpValidator(value);
                if (!result) {
                    result = false;
                    msg = "Sorry, it seems like the OTP you entered is not valid."
                }
                break;
            case "email":
                result = this._emailValidator(value);
                if (!result) {
                    result = false;
                    msg = "Sorry, the email you entered is not valid. Please enter a valid email address."
                }
                break;
            case "organization":
                result = this._organizationValidator(value);
                if (!result) {
                    result = false;
                    msg = "Sorry, the organization you entered is not valid."
                }
                break;
            default:
                result = true;
                msg = "";
        }
        return { valid: result, msg: msg }
    }
}

export default Validator;