import { useContext } from "react";
import Popup from "reactjs-popup";
import { IndicVoiceContext } from "../state/IndicVoiceContext";

const Detail = ({ label, value }) => {
  const cleanLabels = (label) => {
    return label.replaceAll("_", " ");
  };

  return (
    <div className="flex items-center justify-between gap-4">
      <p className="text-sm font-semibold capitalize">{cleanLabels(label)}:</p>
      <p className="text-sm capitalize">{value}</p>
    </div>
  );
};

const AudioInfoModal = () => {
  const { data, editModalInfo } = useContext(IndicVoiceContext);
  const { modal } = data;
  const { modalInfo, showInfoModal } = modal;

  return (
    <Popup
      open={showInfoModal}
      closeOnDocumentClick={true}
      closeOnEscape={false}
      onClose={() => {
        editModalInfo({ showInfoModal: false });
      }}
    >
      <div className="bg-slate-100 shadow rounded-lg p-4 px-8">
        <h4 className="text-lg font-semibold underline">Audio Details</h4>
        <div className="flex flex-col gap-1 mt-4">
          <Detail label={"speaker_id"} value={modalInfo["speaker_id"]} />
          <Detail label={"language"} value={modalInfo["language"]} />
          <Detail
            label={"audio_raw_duration"}
            value={modalInfo["audio_raw_duration"]}
          />
          <Detail label={"scenario"} value={modalInfo["scenario"]} />
          <Detail label={"task_name"} value={modalInfo["task_name"]} />
          <Detail label={"gender"} value={modalInfo["gender"]} />
          <Detail label={"age_group"} value={modalInfo["age_group"]} />
          <Detail label={"job_type"} value={modalInfo["job_type"]} />
          <Detail label={"qualification"} value={modalInfo["qualification"]} />
          <Detail label={"area"} value={modalInfo["area"]} />
          <Detail label={"district"} value={modalInfo["district"]} />
          <Detail label={"state"} value={modalInfo["state"]} />
          <Detail label={"occupation"} value={modalInfo["occupation"]} />
          <Detail
            label={"verification_report"}
            value={modalInfo["verification_report"]["decision"]}
          />
        </div>
      </div>
    </Popup>
  );
};

export default AudioInfoModal;
