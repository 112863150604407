import React, { useState } from "react";

const Options = ({ options, currentOption, setOption, disabled }) => {
  return (
    <select
      className="p-2 rounded bg-slate-200"
      disabled={disabled}
      value={currentOption}
      onChange={(event) => {
        setOption(event.target.value);
      }}
    >
      {options.map((option) => {
        return (
          <option key={option} value={option}>
            {option}
          </option>
        );
      })}
    </select>
  );
};

export default Options;
