const TranscriptionBox = ({ heading, audioDetails, timestamp }) => {
  return (
    <div>
      <p className="font-medium capitalize underline">{heading}</p>
      <div
        className={`${
          audioDetails["language"] === "Urdu" ||
          audioDetails["language"] === "Kashmiri"
            ? "rtl-grid"
            : ""
        } `}
      >
        <span className="">
          {audioDetails[heading].map((section) => {
            return (
              <span
                key={section["start"]}
                className={`font-large ${
                  section.start <= timestamp && section.end >= timestamp
                    ? "text-green-500"
                    : "text-slate-600"
                }`}
              >
                {`${section["text"]} `}
              </span>
            );
          })}
        </span>
      </div>
    </div>
  );
};

export default TranscriptionBox;
