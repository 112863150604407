const LogoutButton = ({ logout }) => {
  return (
    <button
      className="flex items-center gap-1 md:px-1 md:shadow-lg rounded md:ring-1 ring-black"
      onClick={() => logout()}
    >
      <h2 className="text-sm font-medium">Logout</h2>
      <svg
        className="hidden md:block"
        xmlns="http://www.w3.org/2000/svg"
        height="20"
        viewBox="0 -960 960 960"
        width="20"
      >
        <path d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h280v80H200v560h280v80H200Zm440-160-55-58 102-102H360v-80h327L585-622l55-58 200 200-200 200Z" />
      </svg>
    </button>
  );
};

export default LogoutButton;
