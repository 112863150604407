import { useContext } from "react";
import { IndicVoiceContext } from "../state/IndicVoiceContext";

const InfoButton = ({ showModal }) => {
  return (
    <button onClick={() => showModal()}>
      <svg
        className="hover:border"
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        viewBox="0 -960 960 960"
        width="24"
        fill="currentColor"
      >
        <path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
      </svg>
    </button>
  );
};

const DownloadPlayButton = ({
  getAudioData,
  audioExist,
  setAudioData,
  currentPlayingAudio,
}) => {
  const actionFunction = () => {
    audioExist ? setAudioData() : getAudioData();
  };

  return (
    <button className="pl-2" onClick={() => actionFunction()}>
      {currentPlayingAudio ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24"
          viewBox="0 -960 960 960"
          width="24"
          fill="#000000"
        >
          <path d="M200-312v-336l240 168-240 168Zm320-8v-320h80v320h-80Zm160 0v-320h80v320h-80Z" />
        </svg>
      ) : (
        <svg
          className="hover:border"
          xmlns="http://www.w3.org/2000/svg"
          height="24"
          viewBox="0 -960 960 960"
          width="24"
        >
          <path d="M320-200v-560l440 280-440 280Z" />
        </svg>
      )}
    </button>
  );
};

const DataListItem = ({
  info,
  audioExist,
  setAudioData,
  index,
  setTimestamp,
  currentPlayingAudio,
}) => {
  const { editModalInfo, getAudioFile } = useContext(IndicVoiceContext);

  const getAudioData = () => {
    const { filename, version, language } = info;
    setTimestamp(0.0);
    getAudioFile(language, version, filename);
  };

  const editModelDetails = () => {
    editModalInfo({ showInfoModal: true, modalInfo: info });
  };

  return (
    <tr className={`${index % 2 === 0 ? "bg-white" : "bg-slate-100"}`}>
      <td>
        <DownloadPlayButton
          getAudioData={getAudioData}
          audioExist={audioExist}
          setAudioData={setAudioData}
          currentPlayingAudio={currentPlayingAudio}
        />
      </td>
      <td>
        <p className="py-2 text-xs">{info["filename"]}</p>
      </td>
      <td>
        <p className="py-2 text-xs">{info["language"]}</p>
      </td>
      <td>
        <p className="py-2 text-xs">{info["audio_raw_duration"]}</p>
      </td>
      <td>
        <p className="py-2 text-xs">{info["gender"]}</p>
      </td>
      <td>
        <p className="py-2 text-xs">{info["age_group"]}</p>
      </td>
      <td>
        <p className="py-2 text-xs">{info["state"]}</p>
      </td>
      <td>
        <p className="py-2 text-xs">{info["district"]}</p>
      </td>
      <td>
        <p className="py-2 text-xs">{info["scenario"]}</p>
      </td>

      <td>
        <InfoButton showModal={editModelDetails} />
      </td>
    </tr>
  );
};

export default DataListItem;
