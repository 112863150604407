import { useContext, useEffect, useState } from "react";
import { IndicVoiceContext } from "../state/IndicVoiceContext";
import SubHeading from "./SubHeading";
import DownlaodListItem from "./DownloadListItem";

const DownloadList = () => {
  const { data, getDownloadList } = useContext(IndicVoiceContext);
  const { downloadList, authInfo } = data;
  const [downloadInfoMetadata, setDownloadInfoMetadata] = useState({});

  useEffect(() => {
    getDownloadList();
  }, []);

  useEffect(() => {
    const groups = Object.groupBy(downloadList, ({ version }) => version);
    setDownloadInfoMetadata(groups);
  }, [downloadList]);

  return (
    <div className="bg-white p-4">
      <SubHeading heading={"Downloads"} />
      <span className="text-sm">
        Please click{" "}
        <a
          className="underline text-blue-400"
          href={`https://indicvoices.ai4bharat.org/backend/download_dataset/TranscriptionRulesL2.pdf?token=${authInfo.jwt}`}
        >
          here
        </a>{" "}
        to download the guidelines for L2 Transcription.
      </span>
      <div className="mt-4 overflow-x-scroll no-scrollbar">
        {Object.keys(downloadInfoMetadata).map((key) => {
          const infos = downloadInfoMetadata[key].sort((a, b) => {
            if (a["language"] > b["language"]) {
              return 1;
            } else if (a["language"] < b["language"]) {
              return -1;
            } else {
              return 0;
            }
          });
          return (
            <DownlaodListItem
              key={key}
              token={authInfo.jwt}
              version={key}
              infos={downloadInfoMetadata[key]}
            />
          );
        })}
      </div>
      <p className="text-xs font-semibold mt-4">
        Note: If you encounter issues with the download, kindly log out and then
        log back in.
      </p>
    </div>
  );
};

export default DownloadList;
