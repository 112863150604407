import React, { useState } from "react";
import SubHeading from "./SubHeading";

const Contact = () => {
  return (
    <div className="bg-white p-4 md:rounded">
      <SubHeading heading={"Contact"} />
      <ul className="list-disc pl-4">
        <li>
          Tahir Javed (
          <a className="text-blue-700" href="mailto:tahir@cse.iitm.ac.in">
            tahir@cse.iitm.ac.in
          </a>
          )
        </li>
        <li>
          Mitesh Khapra (
          <a className="text-blue-700" href="mailto:miteshk@cse.iitm.ac.in">
            miteshk@cse.iitm.ac.in
          </a>
          )
        </li>
      </ul>
    </div>
  );
};

export default Contact;
