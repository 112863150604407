import React from "react";

const Heading = () => {
  return (
    <div className="flex flex-col items-center bg-orange-300 py-8 md:rounded-b">
      <h1 className="text-4xl md:text-6xl lg:text-8xl font-bold text-black">
        IndicVoices
      </h1>
      <h3 className="text-black text-sm md:text-xl text-center font-medium">
        Towards building an Inclusive Multilingual Speech Dataset for Indian
        Languages
      </h3>
      <h4 className="text-black text-xs md:text-xs text-center font-small">
        Funded by Bhashini, Ministry of Electronics and Information Technology, Government of India
      </h4>
      <h4 className="text-black text-xs md:text-xs text-center font-small">
        Also supported by generous grants from EkStep Foundation and Nilekani Philanthropies
      </h4>
    </div>
  );
};

export default Heading;
