import React, { useState, useRef, useEffect, useContext } from "react";
import WaveSurfer from "wavesurfer.js";
import SubHeading from "./SubHeading";
import { IndicVoiceContext } from "../state/IndicVoiceContext";
import RegionsPlugin from "wavesurfer.js/dist/plugins/regions.esm.js";
import PlayButton from "./PlayButton";

const AudioWaveForm = ({ setTimestamp }) => {
  const waveSurfer = useRef(null);
  const [currentTimeStamp, setCurrentTimeStamp] = useState(0);
  const [previousSegmentTimeStamp, setPreviousSegmentTimeStamp] = useState(0);
  const [previousAudioFile, setPreviousAudioFile] = useState("");
  // const [playing, setPlaying] = useState(false);
  const region = useRef(null);

  const { data, setPlaying } = useContext(IndicVoiceContext);
  const { currentAudioFile, currentAudioId, currentAudioInfo, playing } =
    data.currentAudioDetails;

  const getSegment = (timestamp) => {
    const segments = currentAudioInfo["verbatim"];
    const segment = segments.filter((segment) => {
      return segment.start <= timestamp && segment.end >= timestamp;
    });
    if (segment.length >= 1) {
      return { segmentStart: segment[0].start, segmentEnd: segment[0].end };
    }
    return { segmentStart: 0, segmentEnd: 0 };
  };

  useEffect(() => {
    if (region.current !== null) {
      const regionCount = region.current.getRegions().length;
      const { segmentStart, segmentEnd } = getSegment(currentTimeStamp);
      if (segmentEnd === 0 && segmentStart === 0) {
        if (regionCount !== 0) {
          region.current.clearRegions();
        }
        return;
      }
      if (segmentStart <= currentTimeStamp && segmentEnd >= currentTimeStamp) {
        if (regionCount === 0 && segmentEnd !== 0 && segmentStart !== 0) {
          setPreviousSegmentTimeStamp(segmentStart);
          region.current.addRegion({
            start: segmentStart,
            end: segmentEnd,
            color: "rgba(0,255,0,0.3)",
            drag: false,
            resize: false,
          });
        }
      }
      if (previousSegmentTimeStamp !== segmentStart) {
        if (regionCount > 0) {
          region.current.clearRegions();
        }
      }
    }
  }, [currentTimeStamp]);

  useEffect(() => {
    if (currentAudioFile !== "") {
      // Destroying the previous wavesurfer object. Need to destroy for creating new player with new audio
      if (waveSurfer.current !== null) {
        waveSurfer.current.destroy();
      }
      setPreviousAudioFile(currentAudioFile);
      waveSurfer.current = WaveSurfer.create({
        container: document.querySelector("#waveform"),
        waveColor: "orange",
        url: currentAudioFile,
        height: 100,
        barGap: 1,
        barRadius: 1,
        barWidth: 2,
        dragToSeek: false,
        barHeight: 3,
        mediaControls: false,
        autoplay: false,
      });

      region.current = waveSurfer.current.registerPlugin(
        RegionsPlugin.create()
      );
      waveSurfer.current.on("ready", () => {});
      waveSurfer.current.on("click", () => {
        if (region.current !== null) {
          region.current.clearRegions();
        }
      });
      waveSurfer.current.on("ready", () => {
        setTimestamp(0.0);
        setCurrentTimeStamp(0.0);
      });
      waveSurfer.current.on("play", () => {
        setPlaying(true);
      });
      waveSurfer.current.on("pause", () => {
        setPlaying(false);
      });
      waveSurfer.current.on("timeupdate", (currentTime) => {
        setTimestamp(currentTime);
        setCurrentTimeStamp(currentTime);
      });
    }
  }, [currentAudioFile]);

  return (
    <div className="rounded bg-white p-4">
      <SubHeading heading={"Preview"} setTimestamp={setTimestamp} />
      <div className="flex flex-row gap-4 items-center">
        <div className="flex-2">
          {waveSurfer.current ? (
            <PlayButton
              playing={playing}
              onClick={() => waveSurfer.current.playPause()}
            />
          ) : null}
        </div>
        <div className="flex-1" id="waveform"></div>
      </div>
    </div>
  );
};

export default AudioWaveForm;
