import axios from 'axios';
import { createContext, useReducer } from "react";
import { IndicVoiceReducer } from "./IndicVoiceReducer";
import Validator from '../helper/verifier';

export const IndicVoiceContext = createContext();

const IndicVoiceProvider = ({ children }) => {
    const initState = {
        currentPage: "home",
        loading: false,
        audioDetails: [],
        filteredAudioDetails: [],
        audioFileList: {},
        authInfo: {
            name: "",
            organization: "",
            phone: "",
            email: "",
            otp: "",
            jwt: "",
            otpSendStatus: false,
            loading: false
        },
        loginInfo: {
            loginEmail: "",
            loginOtp: "",
            loading: false,
        },
        downloadStatus: {
            downloading: false,
            progress: 0
        },
        downloadList: [],
        errors: {
            name: "",
            organization: "",
            phone: "",
            email: "",
            otp: "",
            loginEmail: "",
            loginOtp: "",
            loginError: "",
            registerError: "",
            verifyOtpError: ""
        },
        filterOptions: {
            ageGroup: [],
            language: [],
            gender: [],
            style: []
        },
        currentAudioDetails: {
            currentAudioFile: "",
            currentAudioId: "",
            currentAudioInfo: {},
            timestamp: 0.0,
            playing: false
        },
        modal: {
            showInfoModal: false,
            modalInfo: {
                "language": "",
                "audio_raw_duration": 0,
                "scenario": "",
                "task_name": "",
                "gender": "",
                "age_group": "",
                "job_type": "",
                "qualification": "",
                "area": "",
                "district": "",
                "state": "",
                "occupation": "",
                "audio_segmented_duration": 0,
                "speaker_id": "",
                "verification_report": {
                    "decision": "",
                }
            }
        },
    }
    const [indicVoiceState, dispatch] = useReducer(
        IndicVoiceReducer, initState
    )
    const editCurrentPage = (page) => {
        editErrors({
            name: "",
            organization: "",
            phone: "",
            email: "",
            otp: "",
            loginEmail: "",
            loginOtp: "",
            loginError: "",
            registerError: "",
            verifyOtpError: ""
        },)
        dispatch({
            type: "EDIT_CURRENT_PAGE",
            payload: page
        })

    }

    const editErrors = (data) => {
        dispatch({
            type: "EDIT_ERROR",
            payload: data
        })
    }

    const setToken = (token) => {
        dispatch({
            type: 'EDIT_AUTH_INFO',
            payload: { jwt: token }
        })
    }

    const getSavedToken = () => {
        editLoading(true);
        let token = localStorage.getItem('dmu_account_token')
        if (!token) {
            token = "";
        }
        setToken(token)
        editLoading(false);
    }

    const logout = () => {
        localStorage.removeItem("dmu_account_token")
        editAuthInfo(
            {
                name: "",
                organization: "",
                phone: "",
                email: "",
                otp: "",
                jwt: "",
                otpSendStatus: false
            }
        )
        editLoginInfo({
            loginEmail: "",
            loginOtp: "",
            loading: false,
        })
        editErrors({
            name: "",
            organization: "",
            phone: "",
            email: "",
            otp: "",
            loginEmail: "",
            loginOtp: "",
            loginError: "",
            registerError: "",
            verifyOtpError: ""
        },)
    }

    const editLoading = (status, type = "basic") => {
        if (type === 'basic')
            dispatch({
                type: "EDIT_LOADING",
                payload: status
            })
        else if (type === 'login') {
            dispatch({
                type: "EDIT_LOGIN_INFO",
                payload: { "loading": status }
            })
        }
        else if (type === 'register') {
            dispatch({
                type: "EDIT_AUTH_INFO",
                payload: { "loading": status }
            })
        }
    }

    const editFilterOptions = (data) => {
        dispatch({
            type: "EDIT_FILTER_OPTIONS",
            payload: data
        })
    }

    const editAuthInfo = (data) => {
        dispatch({
            type: "EDIT_AUTH_INFO",
            payload: data
        })
    }

    const editLoginInfo = (data) => {
        dispatch({
            type: "EDIT_LOGIN_INFO",
            payload: data
        })
    }

    const setCurrentAudioFile = (audioUrl, audioId, audioInfo) => {
        dispatch({
            type: "SET_CURRENT_AUDIO",
            payload: {
                "currentAudioFile": audioUrl, "currentAudioId": audioId, "currentAudioInfo": audioInfo,
                "timestamp": 0.0,
                "playing": false
            }
        })
    }
    const setTimestamp = (timestamp) => {
        dispatch({
            type: "SET_CURRENT_TIMESTAMP",
            payload: timestamp
        })
    }

    const setPlaying = (status) => {
        dispatch({
            type: "SET_PLAYING",
            payload: status
        })
    }

    const addToAudioFileList = (audioId, audioUrl) => {
        dispatch({
            type: "ADD_TO_AUDIO_FILE_LIST",
            payload: { audioId, audioUrl }
        })
    }

    const editModalInfo = (data) => {
        dispatch({
            type: "EDIT_MODAL_INFO",
            payload: data
        })
    }

    const addAudioDetails = (data) => {
        // addFilteredAudioDetails(data)
        dispatch({
            type: "ADD_AUDIO_DETAILS",
            payload: data
        })
    }

    const filterData = (filterType, filter, data) => {
        if (filter.length <= 0 || data.length <= 0) {
            return data;
        }
        let filteredData = data.filter((audioInfo) => {
            return filter.includes(audioInfo[filterType])
        })
        return filteredData;
    }

    const addFilteredAudioDetails = (audioDetails) => {
        editLoading(true)
        const { filterOptions } = indicVoiceState;
        const { ageGroup, language, gender, style } = filterOptions;
        let data = Array.isArray(audioDetails) ? audioDetails : Object.values(audioDetails)
        data = filterData('age_group', ageGroup, data)
        data = filterData('language', language, data)
        data = filterData('gender', gender, data)
        data = filterData('scenario', style, data)

        dispatch({
            type: "ADD_FILTERED_AUDIO_DETAILS",
            payload: data
        })

        editLoading(false)
    }


    const getData = async () => {
        const { loading, filterOptions } = indicVoiceState;
        if (loading) {
            return
        }
        try {
            const { language, ageGroup, gender, style } = filterOptions;
            const body = { language, "age_group": ageGroup, gender, "audio_style": style }
            editLoading(true);
            const result = await axios.post(
                "https://indicvoices.ai4bharat.org/backend/hello",
                body
            )
            addAudioDetails(result.data);
        } catch (error) {
            // console.log(error)
            console.log("")
        } finally {
            editLoading(false)
        }
    }

    const getAudioFile = async (language, version, fileId) => {
        const { loading, audioDetails } = indicVoiceState;
        const requiredFileInfo = audioDetails[fileId]
        if (loading || !requiredFileInfo) {
            return;
        }
        try {
            editLoading(true);
            const response = await axios.get(
                `https://indicvoices.ai4bharat.org/backend/serve_file/${language}/${version}/${fileId}`,
                { responseType: "blob" }
            )
            var audioBlob = new Blob([response.data], { type: "audio/wav" });
            var audioUrl = URL.createObjectURL(audioBlob);
            setCurrentAudioFile(audioUrl, fileId, requiredFileInfo);
            addToAudioFileList(fileId, audioUrl);
        } catch (error) {
            // console.log(error);
            console.log("");
        } finally {
            editLoading(false);
        }
    }

    //Login
    const checkIfUserExist = async (email) => {
        const { loading } = indicVoiceState;
        const validator = new Validator()
        const { valid, msg } = validator.validate("email", email)

        editErrors({ 'loginError': "", "loginEmail": msg })
        if (!valid) {
            return
        }

        try {
            editLoading(true, 'login');
            const response = await axios.post(
                `https://indicvoices.ai4bharat.org/backend/user/skip_otp`,
                { email: email, name: "", organization: "", phone: "", otp: "", jwt: "" }
            )
            if (response.status === 200) {
                editAuthInfo(response.data)
                localStorage.setItem("dmu_account_token", response.data.jwt)
            }
        } catch (error) {
            if (!error.response) {
                editErrors({ "loginError": "Server Error" })
            }
            else if (error.response.status === 401) {
                editErrors({ 'loginError': "User not found! Please register" })
            }
            // console.log(error)
        }
        finally {
            editLoading(false, 'login')
        }
    }

    //Sending OTP
    const sendRegistrationOtp = async () => {
        const { email, name, organization, phone, otp, jwt } = indicVoiceState.authInfo;
        const validator = new Validator()
        let invalidCount = 0;
        for (const key of ["email", "name", "organization", "phone"]) {
            const { valid, msg } = validator.validate(key, indicVoiceState.authInfo[key]);
            if (!valid) {
                invalidCount += 1
            }
            editErrors({ [key]: msg })
        }

        if (invalidCount !== 0) {
            return;
        }

        try {
            editLoading(true, 'register');
            const response = await axios.post(
                `https://indicvoices.ai4bharat.org/backend/otp/generate`,
                { email, name, organization, phone, otp, jwt }
            )
            if (response.status === 200) {
                editAuthInfo({ "otpSendStatus": true });
            }
        } catch (error) {
            if (!error.response) {
                editErrors({ "registerError": "Please try again" })
            } else {
                editErrors({ "registerError": "Please try again" })
            }
            // console.log(error)
        }
        finally {
            editLoading(false, 'register')
        }
    }
    //Verifying the OTP and finish Registration
    const completeRegistration = async () => {
        const { email, name, organization, phone, otp, jwt } = indicVoiceState.authInfo;

        const validator = new Validator()
        const { valid, msg } = validator.validate("otp", otp)
        editErrors({ "otp": msg, "verifyOtpError": "" })
        if (!valid) {
            return
        }

        try {
            editLoading(true, 'register');
            const response = await axios.post(
                `https://indicvoices.ai4bharat.org/backend/otp/verify`,
                { email, name, organization, phone, otp, jwt }
            )
            if (response.status === 200) {
                editAuthInfo({ "jwt": response.data.jwt });
                localStorage.setItem("dmu_account_token", response.data.jwt)
            }
        } catch (error) {
            if (!error.response) {
                editErrors({ "verifyOtpError": "Server Error" })
            }
            else if (error.response.status === 401) {
                editErrors({ "verifyOtpError": "Invalid OTP" })
            }
            console.log("")
        }
        finally {
            editLoading(false, 'register')
        }
    }
    const processDownloadList = (downloadList) => {
        if (downloadList.length > 0) {
            const result = downloadList.map((item) => {
                const tmpList = item.replace(".tgz", "").split("_")
                return { 'fileName': item, language: tmpList[1], version: tmpList[0], split: tmpList[2] }
            })
            return result;
        }
        return [];
    }

    const getDownloadList = async () => {
        const { email, name, organization, phone, otp, jwt } = indicVoiceState.authInfo;
        try {
            editLoading(true);
            const response = await axios.post(
                `https://indicvoices.ai4bharat.org/backend/download_filelist`,
                { email, name, organization, phone, otp, jwt }
            )
            if (response.status === 200) {
                const result = processDownloadList(response.data);
                dispatch({
                    type: "ADD_DOWNLOAD_LIST",
                    payload: result
                })
            }

        } catch (error) {
            //console.log(error)
            console.log("")
        }
        finally {
            editLoading(false)
        }
    }

    return (
        <IndicVoiceContext.Provider
            value={{
                data: indicVoiceState, setTimestamp, logout, editCurrentPage, getDownloadList,
                getSavedToken, completeRegistration, sendRegistrationOtp, checkIfUserExist,
                editLoginInfo, editAuthInfo, editLoading, getData, setCurrentAudioFile,
                addToAudioFileList, getAudioFile, editModalInfo, editFilterOptions,
                setPlaying, addFilteredAudioDetails
            }}
        >
            {children}
        </IndicVoiceContext.Provider>
    )
}
export default IndicVoiceProvider;