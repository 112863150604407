import { useContext } from "react";

const Input = ({
  heading,
  component,
  hint,
  id,
  notRequired,
  state,
  validateField,
}) => {
  // const { data, validateField } = useContext(FormContext);
  const { errors } = state;

  // const checkInputValidity = (event) => {
  //   validateField(id);
  // };

  return (
    <div
      className="flex flex-col mb-2 w-full"
      // onClickCapture={(event) => checkInputValidity(event)}
      // onBlur={(event) => checkInputValidity(event)}
    >
      <div className="mb-2">
        <label
          htmlFor={id}
          className="uppercase block font-bold text-xs text-slate-500"
        >
          {heading}
          {notRequired ? null : (
            <label htmlFor={id} className="text-red-600">
              *
            </label>
          )}
        </label>
        {hint && <p className="text-xs text-slate-500">{hint}</p>}
      </div>
      <div>
        {component}
        {errors[id] ? (
          <p className="text-red-500 text-sm font-medium">{errors[id]}</p>
        ) : null}
      </div>
    </div>
  );
};

export default Input;
