import React, { useState } from "react";
import Divider from "./Divider";
import SubHeading from "./SubHeading";
import metadata from "../assets/metadata.json";
import Options from "./Options";
import Button from "./Button";

const Leaderboard = () => {
  const taskList = ["ASR"];
  const [task, setTask] = useState(taskList[0]);

  return (
    <div className="bg-white p-4 md:rounded">
      <SubHeading heading={"Leaderboard"} />
      <p className="text-sm"></p>

      <Options
        task={task}
        setTask={setTask}
        options={taskList}
        disabled={true}
      />
      <div className="overflow-x-scroll mt-2 no-scrollbar rounded">
        <table className="border-spacing-x-2 border-spacing-y-2">
          <thead className="rounded">
            <tr className="rounded bg-slate-200">
              <th className="p-2 px-4 font-medium text-sm">Rank</th>
              <th className="px-4 font-medium text-sm">Model</th>
              <th className="px-4 font-medium text-sm">Assamese</th>
              <th className="px-4 font-medium text-sm">Bengali</th>
              <th className="px-4 font-medium text-sm">Bodo</th>
              <th className="px-4 font-medium text-sm">Dogri</th>
              <th className="px-4 font-medium text-sm">Gujarati</th>
              <th className="px-4 font-medium text-sm">Hindi</th>
              <th className="px-4 font-medium text-sm">Kannada</th>
              <th className="px-4 font-medium text-sm">Kashmiri</th>
              <th className="px-4 font-medium text-sm">Konkani</th>
              <th className="px-4 font-medium text-sm">Maithili</th>
              <th className="px-4 font-medium text-sm">Malayalam</th>
              <th className="px-4 font-medium text-sm">Manipuri</th>
              <th className="px-4 font-medium text-sm">Marathi</th>
              <th className="px-4 font-medium text-sm">Nepali</th>
              <th className="px-4 font-medium text-sm">Odia</th>
              <th className="px-4 font-medium text-sm">Punjabi</th>
              <th className="px-4 font-medium text-sm">Sanskrit</th>
              <th className="px-4 font-medium text-sm">Santali</th>
              <th className="px-4 font-medium text-sm">Sindhi</th>
              <th className="px-4 font-medium text-sm">Tamil</th>
              <th className="px-4 font-medium text-sm">Telugu</th>
              <th className="px-4 font-medium text-sm">Urdu</th>
            </tr>
          </thead>
          <tbody>
            {metadata[task.toLowerCase()].map((info) => {
              return (
                <tr key={info["model"]} className="">
                  {Object.keys(info).map((metric) => {
                    return (
                      <td key={metric} className="py-2 px-4 text-sm">
                        {info[metric]}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Button label={"Submit model [Coming soon]"} onClick={() => {}} />
    </div>
  );
};

export default Leaderboard;
