const PlayButton = ({ playing, onClick }) => {
  return (
    <button
      className="border rounded-lg flex flex-col"
      onClick={() => onClick()}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="50"
        viewBox="0 -960 960 960"
        width="50"
        fill="#F3BD7F"
      >
        <path d="M200-312v-336l240 168-240 168Zm320-8v-320h80v320h-80Zm160 0v-320h80v320h-80Z" />
      </svg>
    </button>
  );
};
export default PlayButton;
