export const IndicVoiceReducer = (state, action) => {
    switch (action.type) {
        case 'EDIT_LOADING':
            return { ...state, loading: action.payload }
        case 'EDIT_MODAL_INFO':
            return { ...state, modal: { ...state.modal, ...action.payload } }
        case 'SET_CURRENT_AUDIO':
            return { ...state, currentAudioDetails: { ...state.currentAudioDetails, ...action.payload } }
        case 'SET_CURRENT_TIMESTAMP':
            return { ...state, currentAudioDetails: { ...state.currentAudioDetails, timestamp: action.payload } }
        case 'SET_PLAYING':
            return { ...state, currentAudioDetails: { ...state.currentAudioDetails, playing: action.payload } }
        case 'ADD_TO_AUDIO_FILE_LIST':
            return { ...state, audioFileList: { ...state.audioFileList, [action.payload.audioId]: action.payload.audioUrl } }
        case 'ADD_AUDIO_DETAILS':
            const fullTmpData = createObjectFromArray(action.payload)
            return { ...state, audioDetails: { ...state.audioDetails, ...fullTmpData } }
        case 'ADD_FILTERED_AUDIO_DETAILS':
            const filteredTmpData = createObjectFromArray(action.payload)
            return { ...state, filteredAudioDetails: filteredTmpData }
        case 'EDIT_FILTER_OPTIONS':
            return { ...state, filterOptions: { ...state.filterOptions, ...action.payload } }
        case 'EDIT_AUTH_INFO':
            return { ...state, authInfo: { ...state.authInfo, ...action.payload } }
        case 'EDIT_LOGIN_INFO':
            return { ...state, loginInfo: { ...state.loginInfo, ...action.payload } }
        case 'ADD_DOWNLOAD_LIST':
            return { ...state, downloadList: action.payload }
        case 'EDIT_DOWNLOAD_PROGRESS':
            return { ...state, downloadStatus: { ...state.downloadStatus, ...action.payload } }
        case 'EDIT_CURRENT_PAGE':
            return { ...state, currentPage: action.payload }
        case 'EDIT_ERROR':
            return { ...state, errors: { ...state.errors, ...action.payload } }
        default:
            return state;
    }
}

// For converting an array of objects from audio details to an object of objects
const createObjectFromArray = (arr) => {
    let result = {}
    if (arr) {
        for (let row of arr) {
            if (row) {
                result[row['filename']] = row;
            }
        }
    }
    return result;
}