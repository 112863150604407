import React, { useContext } from "react";
import Heading from "../components/Heading";
import Leaderboard from "../components/Leaderboard";
import Details from "../components/Details";
import DownloadButtons from "../components/DownloadButtons";
import Contact from "../components/Contact";
import { IndicVoiceContext } from "../state/IndicVoiceContext";

const Home = () => {
  const { editCurrentPage } = useContext(IndicVoiceContext);

  return (
    <div className="bg-slate-100 pb-16 h-screen">
      <div className="bg-slate-100 md:container md:mx-auto">
        <Heading />
        <div className="mb-1"></div>
        <div className="grid grid-rows-1 lg:grid-cols-9 gap-1 items-stretch">
          <div className="lg:col-span-8">
            <Details />
          </div>
          <div className="flex flex-cols h-full items-center justify-center p-2 bg-white lg:bg-slate-200 border-0 border-indigo-600 md:rounded">
            <DownloadButtons editCurrentPage={editCurrentPage} />
          </div>
        </div>
        <div className="mb-1"></div>
        <Leaderboard />
        <div className="mb-1"></div>
        <Contact />
      </div>
    </div>
  );
};

export default Home;
